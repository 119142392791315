import {
  ComponentClientSpecMapEntry,
  ComponentRef,
  type TPAPublicData,
  type StyleParams,
} from '@wix/editor-platform-sdk-types';

import { GROUPS_APP_DEFINITION_ID } from '../../../constants';
import { EditorPlatformMigration } from './EditorPlatformMigration';
import {
  toColorStyleParams,
  toFontStyleParams,
  toNumericStyleParams,
  toBooleanStyleParams,
} from './helpers';

export abstract class Settings2StylesMigration extends EditorPlatformMigration {
  protected abstract migration: (
    styles: StyleParams,
    data: TPAPublicData,
  ) => Partial<StyleParams>;

  async migrate(component: ComponentClientSpecMapEntry, ref: ComponentRef) {
    const [params, publicData] = await Promise.all([
      this.sdk.document.tpa.getStyleParams(GROUPS_APP_DEFINITION_ID, {
        compRef: ref,
      }),
      this.sdk.document.tpa.data.getAll(GROUPS_APP_DEFINITION_ID, {
        compRef: ref,
      }),
    ]);

    const result = this.migration(params, publicData);

    const fonts = toFontStyleParams(result.fonts);
    const colors = toColorStyleParams(result.colors);
    const numbers = toNumericStyleParams(result.numbers);
    const booleans = toBooleanStyleParams(result.booleans);

    const styleParams = [fonts, colors, numbers, booleans].flat();

    if (styleParams.length === 0) {
      return true;
    }

    await this.sdk.document.tpa.setStyleParams(GROUPS_APP_DEFINITION_ID, {
      styleParams,
      compRef: ref,
    });

    return true;
  }
}
