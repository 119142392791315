import parseColor from 'color';
import type { IWixStyleColor, IWixStyleFont } from '@wix/yoshi-flow-editor';
import type {
  ColorStyleParam,
  FontStyleParam,
  NumericStyleParam,
  BooleanStyleParam,
} from '@wix/editor-platform-sdk-types';

/**
 * map tpa-settings `IWixStyleColor` to platform `ColorStyleParam`
 */
export function toColorStyleParams(
  colors: Record<string, IWixStyleColor> = {},
): ColorStyleParam[] {
  return Object.entries(colors).map<ColorStyleParam>(
    ([key, color]): ColorStyleParam => ({
      key,
      type: 'color',
      param: {
        value: {
          color: false,
          opacity: parseColor(color.value).alpha(),
          rgba: parseColor(color.value).rgb().string(),
        },
      },
    }),
  );
}

/**
 * map tpa-settings `IWixStyleFont` to platform `FontStyleParam`
 */
export function toFontStyleParams(
  fonts: Record<string, IWixStyleFont> = {},
): FontStyleParam[] {
  return Object.entries(fonts).map<FontStyleParam>(([key, value]) => ({
    key,
    type: 'font',
    param: { value: value as any },
  }));
}

/**
 * map tpa-settings `number` to platform `NumericStyleParam`
 */
export function toNumericStyleParams(
  numbers: Record<string, number> = {},
): NumericStyleParam[] {
  return Object.entries(numbers).map(([key, value]) => ({
    key,
    type: 'number',
    param: { value },
  }));
}

/**
 * map tpa-settings `number` to platform `NumericStyleParam`
 */
export function toBooleanStyleParams(
  booleans: Record<string, boolean> = {},
): BooleanStyleParam[] {
  return Object.entries(booleans).map(([key, value]) => ({
    key,
    type: 'boolean',
    param: { value },
  }));
}
