import refactoring from 'settings/__migrations__/legacy-mobile-keys';

import { Settings2StylesMigration } from '../Settings2StylesMigration';

/**
 * Migrates legacy settings
 */
export default class extends Settings2StylesMigration {
  version = 3;
  name = 'legacy-mobile-keys';
  migration = refactoring;
}
