import refactoring from 'settings/__migrations__/mobile-font-sizes';

import { Settings2StylesMigration } from '../Settings2StylesMigration';

/**
 * Migrates legacy settings
 */
export default class extends Settings2StylesMigration {
  version = 3;
  name = 'legacy-mobile-font-sizes';
  migration = refactoring;
}
