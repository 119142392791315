import { GROUPS_APP_DEFINITION_ID } from '../../constants';

export enum EApplicationStatus {
  Broken = 'broken',
  Installed = 'installed',
  FirstInstall = 'first-install',
}

// due to red MA build, new version can not be promoted
// so, temporray hardcode
export const GROUPS_IN_MA = {
  appDefinitionId: GROUPS_APP_DEFINITION_ID,
  pageId: 'My Groups',
  widgetId: 'e018cc55-7b1c-4500-a2e5-969f22c8a33a',
} as any;
