import refactoring from 'settings/__migrations__/enums';

import { Settings2StylesMigration } from '../Settings2StylesMigration';

/**
 * Migrates legacy settings
 */
export default class extends Settings2StylesMigration {
  version = 2;
  name = 'settings-enums';
  migration = refactoring;
}
