import refactoring from 'settings/__migrations__/global-refactoring';

import { Settings2StylesMigration } from '../Settings2StylesMigration';

/**
 * Migrates legacy settings
 */
export default class extends Settings2StylesMigration {
  version = 0;
  name = 'styleParams-01';
  migration = refactoring;
}
