import { GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

import {
  EManagementAction,
  EPlatformAction,
  GROUPS_SUPPORT_ARTICLE_ID,
  MY_GROUPS_COMPONENT_ID,
} from '../constants';

/**
 * @see https://github.com/wix-private/editor-platform/blob/master/packages/platform-editor-sdk/docs/articles/manifest-builder/manifest-builder.md
 */
export const getAppManifest: GetAppManifestFn = (
  options,
  sdk,
  context,
  flowApi,
) => {
  const { appManifestBuilder } = options;
  const { t } = flowApi.translations;
  const { isEditorX } = flowApi.environment;

  const builder = appManifestBuilder
    .withJsonManifest({
      pages: {
        pageActions: {
          default: [],
          groupPage: [],
        },
        pageSettings: {
          default: [],
          groupPage: [],
        },
      },
    })
    .configurePagesTab((builder) => {
      builder
        .set({
          displayName: t('groups-web.groups-pages-tab-name'),
        })
        .addAction({
          icon: 'settingsAction',
          title: t('groups-web.manage.actions.main'),
          event: EPlatformAction.OpenDashboard,
        })
        .addAction({
          icon: 'delete_icon',
          title: t('groups-web.app.delete'),
          event: EPlatformAction.Uninstall,
        });
    })
    .configurePages((builder) => {
      builder
        .addAction({
          type: isEditorX
            ? ('Pages_Actions_Page_Rename' as any)
            : 'page_rename',
        })
        .addAction({
          icon: 'delete_icon',
          title: t('groups-web.app.delete'),
          event: EPlatformAction.Uninstall,
        })
        .addSettingsTab({
          title: t('groups-web.page.info'),
          type: 'page_info',
        })
        .addSettingsTab({
          title: t('groups-web.page.layout'),
          type: 'layout',
        })
        .addSettingsTab({
          title: t('groups-web.page.permissions'),
          type: 'permissions',
        })
        .addSettingsTab({
          title: t('groups-web.page.seo'),
          type: 'seo',
        });

      builder.configureState('groupPage', (builder) => {
        builder
          .addSettingsTab({
            title: t('groups-web.page.info'),
            type: 'page_info',
            url: getPanelUrl('Group', 'PageInfo'),
          })
          .addSettingsTab({
            title: t('groups-web.page.layout'),
            type: 'layout',
          })
          .addSettingsTab({
            title: t('groups-web.page.permissions'),
            type: 'permissions',
          });

        builder.addSettingsTab({
          title: t('groups-web.page.seo'),
          type: 'seo',
        });
      });
    })
    .configureWidget(MY_GROUPS_COMPONENT_ID, (builder) => {
      builder.set({
        displayName: t('groups-in-ma.editor.title'),
      });
      builder.behavior().set({ removable: false });
    })
    .configureManagementActions((builder) => {
      builder.learnMoreAction().set({ id: GROUPS_SUPPORT_ARTICLE_ID });

      builder.mainActions().addAction({
        actionId: EManagementAction.MainAction,
        icon: 'appManager_settingsAction',
        title: t('groups-web.manage.actions.main'),
      });

      builder
        .customActions()
        .addAction({
          title: t('groups-web.manage.actions.create.group'),
          actionId: EManagementAction.CreateGroup,
          icon: 'appManager_relatedAppsAction',
          type: 'editorActions',
        })
        .addAction({
          title: t('groups-web.manage.actions.add.widget'),
          actionId: EManagementAction.Addons,
          icon: 'appManager_addElementsAction',
          type: 'editorActions',
        })
        .addAction({
          title: t('groups-web.manage.actions.setup'),
          actionId: EManagementAction.Setup,
          icon: 'appManager_settingsAction',
          type: 'editorActions',
        });
    });

  return builder.build();
};
