import {
  type EditorSDK,
  type ContextParams,
  InstallationOriginType,
} from '@wix/editor-platform-sdk-types';

import { GROUPS_APP_DEFINITION_ID } from '../../constants';
import { EApplicationStatus } from './constants';

export function isSilentInstallation(type?: InstallationOriginType) {
  if (!type) {
    return false;
  }

  return [
    InstallationOriginType.SILENT_INSTALL,
    InstallationOriginType.SILENT_INSTALL_SITE_CREATION,
  ].includes(type);
}

export async function getApplicationStatus(
  applicationId: number,
  sdk: EditorSDK,
  options: ContextParams,
) {
  if (options.firstInstall) {
    return EApplicationStatus.FirstInstall;
  }

  const hasComponents = await hasInstalledComponents(applicationId);

  return hasComponents
    ? EApplicationStatus.Installed
    : EApplicationStatus.Broken;

  async function hasInstalledComponents(applicationId: number) {
    try {
      const components = await sdk.document.tpa.app.getAllCompsByApplicationId(
        GROUPS_APP_DEFINITION_ID,
        applicationId,
      );

      return !!components?.length;
    } catch {
      return false;
    }
  }
}

export async function reinstallApplication(sdk: EditorSDK) {
  await sdk.application.uninstall(GROUPS_APP_DEFINITION_ID, {
    openConfirmation: false,
  });

  await sdk.tpa.add.application(GROUPS_APP_DEFINITION_ID, {
    shouldNavigate: true,
    showPageAddedPanel: true,
    appDefinitionId: GROUPS_APP_DEFINITION_ID,
  });
}
